// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useForm } from "react-hook-form";
import axios from "../axios";
import { useMutation } from "react-query";
import { queryClient } from "..";
import { toast } from "react-hot-toast";
import MRILogoWebP from "./images/mri_logo.webp";

function Login() {
  const { handleSubmit, register } = useForm();
  const loginMutation = useMutation((data) =>
    axios.post("/auth/signin", data).then((res) => res.data)
  );

  const handleFormSubmission = (data) => {
    loginMutation.mutate(data, {
      onSuccess: ({ user }) => {
        queryClient.setQueryData("user", user);
      },
      onError: (err) => {
        if (err.response.status === 400) {
          toast.error("Invalid credentials");
        } else {
          toast.error("Something went wrong, please try again later");
        }
      },
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 16,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          style={{ margin: "1rem" }}
          src={MRILogoWebP}
          alt="logo"
          width={100}
        />
        {/* <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar> */}
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(handleFormSubmission)}
          sx={{ mt: 1 }}
        >
          <TextField
            {...register("email")}
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <TextField
            {...register("password")}
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Login;
