import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import axios from "../axios";
import toast from "react-hot-toast";

const columns = [
  {
    field: "chequeNumber",
    headerName: "Cheque Number",
    width: 150,
  },
  {
    field: "bankName",
    headerName: "Bank Name",
    width: 150,
  },
  {
    field: "chequeDate",
    headerName: "Cheque Date",
    width: 200,
  },
];

const Cheques = () => {
  const { data: cheques, isLoading } = useQuery("cheques", () =>
    axios("/cheques").then((res) => res.data)
  );

  const approveChequesMutation = useMutation(
    (cheques) => {
      return axios.put("/cheques/approve", cheques);
    },
    {
      onSuccess: () => {
        toast.success("Approved successfully");
      },
      onError: () => {
        toast.error("Error approving cheques");
      },
    }
  );

  const bounceChequesMutation = useMutation(
    (cheques) => {
      return axios.put("/cheques/bounce", cheques);
    },
    {
      onSuccess: () => {
        toast.success("Cheques bounced successfully");
        setSelectedCheques([]);
      },
      onError: () => {
        toast.error("Error bouncing cheques");
      },
    }
  );

  const [selectedCheques, setSelectedCheques] = useState([]);

  const approveCheques = () => {
    approveChequesMutation.mutate(selectedCheques);
  };

  const bounceCheques = () => {
    bounceChequesMutation.mutate(selectedCheques);
  };

  return (
    <>
      <Typography
        sx={{
          mb: 2,
        }}
        variant="h5"
        component="h2"
      >
        Cheques
      </Typography>
      <Box
        sx={{
          height: 400,
          width: "100%",
        }}
      >
        <DataGrid
          onSelectionModelChange={(newChequeIDs) => {
            const selectedRowsData = newChequeIDs.map((id) =>
              cheques.find((cheque) => cheque.id === id)
            );
            setSelectedCheques(selectedRowsData);
          }}
          loading={isLoading}
          rows={cheques || []}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
        />
      </Box>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          gap: 2,
        }}
      >
        <Button variant="contained" onClick={approveCheques}>
          Approve Cheques
        </Button>
        <Button variant="contained" onClick={bounceCheques} color="warning">
          Bounce Cheques
        </Button>
      </Box>
    </>
  );
};

export default Cheques;
