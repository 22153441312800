import {
  Autocomplete,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import axios from "../axios";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { useQuery } from "react-query";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";

const reportColumns = [
  { field: "id", headerName: "Receipt No", width: 150 },
  { field: "createdBy", headerName: "Issued By", width: 150 },
  {
    field: "issuedOn",
    headerName: "Issued On",
    width: 150,
    valueGetter: (params) => dayjs(params.row.createdAt).format("DD/MM/YYYY"),
  },
  {
    field: "issuedAt",
    headerName: "Issued At",
    width: 150,
    valueGetter: (params) => dayjs(params.row.createdAt).format("HH:mm:ss"),
  },
  { field: "paymentMethod", headerName: "Payment Method", width: 150 },
  {
    field: "totalReceiptFees",
    headerName: "Receipt Total",
    width: 200,
    valueGetter: (params) => {
      const { receiptFees } = params.row;
      const total = receiptFees.reduce((acc, fee) => acc + fee.feeAmount, 0);
      return `Rs. ${total.toLocaleString()}`;
    },
  },
];

const PaymentsReport = () => {
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [selectedStudent, setSelectedStudent] = useState(null);

  const { data: receipts, isLoading } = useQuery(
    [
      "reports",
      startDate.toISOString(),
      endDate.toISOString(),
      selectedStudent?.id,
    ],
    () => {
      if (!startDate.isValid() || !endDate.isValid()) return;
      return axios("/reports", {
        params: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          studentId: selectedStudent?.id,
        },
      }).then((res) => res.data);
    }
  );

  const { data: students } = useQuery("students", () =>
    axios("/students").then((res) => res.data)
  );

  const getDetailPanelContent = useCallback(
    ({ row }) => (
      <Box p={4}>
        <Typography variant="h6" fontWeight={500} component="h2">
          Individual Fees
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Student ID</TableCell>
                <TableCell>Student Name</TableCell>
                <TableCell>Grade</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Period</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Due Amount</TableCell>
                <TableCell>Discount</TableCell>
                <TableCell align="right">Payment Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.receiptFees.map((fee) => (
                <TableRow
                  key={fee.fee.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{fee.fee.studentID}</TableCell>
                  <TableCell>{fee.fee.student.fullName}</TableCell>
                  <TableCell>{fee.fee.student?.class?.name}</TableCell>
                  <TableCell>{fee.fee.description}</TableCell>
                  <TableCell>{fee.fee.period}</TableCell>
                  <TableCell>{fee.fee.status}</TableCell>
                  <TableCell align="right">{fee.fee.amount}</TableCell>
                  <TableCell align="right">{fee.fee.discount}</TableCell>
                  <TableCell align="right">{fee.feeAmount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    ),
    []
  );

  const getDetailPanelHeight = useCallback(() => "auto", []);

  return (
    <Box>
      <Typography variant="h5" component="h2">
        Payments Report
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Autocomplete
          sx={{ width: 500, mt: 3 }}
          options={students || []}
          onChange={(_, student) => setSelectedStudent(student)}
          getOptionLabel={(student) => `${student.id} | ${student.fullName}`}
          renderInput={(params) => (
            <TextField {...params} label="Student name" />
          )}
        />

        <Box>
          <Typography>Date Range</Typography>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <DatePicker
              inputFormat="DD/MM/YYYY"
              disableFuture
              value={startDate}
              onChange={(newValue) => {
                if (!newValue.isValid()) return;
                setStartDate(newValue);
                if (newValue.isAfter(endDate)) {
                  setEndDate(newValue);
                }
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <Typography>-</Typography>
            <DatePicker
              inputFormat="DD/MM/YYYY"
              disableFuture
              value={endDate}
              onChange={(newValue) => {
                if (!newValue.isValid()) return;
                setEndDate(newValue);
                if (newValue.isBefore(startDate)) {
                  setStartDate(newValue);
                }
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </Box>
      </Box>

      <DataGridPro
        sx={{ mt: 4 }}
        density="compact"
        rows={receipts || []}
        columns={reportColumns}
        autoHeight
        disableSelectionOnClick
        loading={isLoading}
        sortModel={[
          {
            field: "issuedOn",
            sort: "desc",
          },
          {
            field: "issuedAt",
            sort: "desc",
          },
        ]}
        slots={{
          toolbar: GridToolbar,
          footer: () => (
            <Box
              sx={{
                my: 2,
                mr: 2,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <Typography variant="body2" fontWeight={500} component="p">
                Total:
              </Typography>
              <Typography variant="body2" fontWeight={400} component="p">
                Rs.{" "}
                {receipts
                  ? receipts
                      ?.flatMap((receipt) => receipt.receiptFees)
                      .reduce((acc, fee) => acc + fee.feeAmount, 0)
                      .toLocaleString()
                  : 0}
              </Typography>
            </Box>
          ),
        }}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
      />
    </Box>
  );
};

export default PaymentsReport;
