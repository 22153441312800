import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
// import LastPaymentInfo from "../components/LastPaymentInfo";

const Payments = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" component="h2">
          Payments
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              navigate("/payments/new");
            }}
          >
            New Payment
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              navigate("/cheques");
            }}
          >
            Change cheque status
          </Button>
        </Box>
      </Box>
      {/* <LastPaymentInfo /> */}
    </Box>
  );
};
export default Payments;
