import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "../axios";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const NewParent = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const addParentMutation = useMutation((data) => {
    axios.post("/parents", data);
  });
  const handleFormSubmission = (data) => {
    addParentMutation.mutate(data, {
      onSuccess: () => {
        toast.success("Parent added successfully");
        navigate("/parents");
      },
      onError: () => {
        toast.error("Error adding parent");
      },
    });
  };
  return (
    <div>
      <Typography mb={2} variant="h5" component="h2">
        New Parent
      </Typography>
      <Box
        onSubmit={handleSubmit(handleFormSubmission)}
        component="form"
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: 2,
        }}
        autoComplete="off"
      >
        <TextField required label="Full name" {...register("fullName")} />
        <TextField
          label="Identification number"
          required
          {...register("identificationNumber")}
        />
        <TextField label="Phone" required {...register("phone")} />
        <TextField label="Email" type="email" {...register("email")} />
        <TextField
          minRows={4}
          multiline
          label="Address"
          required
          {...register("address")}
        />
        <div></div>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </Box>
    </div>
  );
};

export default NewParent;
