import axios from "axios";

axios.defaults.withCredentials = true;

const isProduction = process.env.NODE_ENV === "production";

const instance = axios.create({
  baseURL: isProduction ? "/api" : "http://localhost:5000/api",
});

export default instance;
