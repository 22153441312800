import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "../axios";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const NewAdmin = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();
  const addUserMutation = useMutation(
    (data) => {
      axios.post("/admins", data);
    },
    {
      onSuccess: () => {
        toast.success("Admin added successfully");
        navigate("/admins");
      },
      onError: () => {
        toast.error("Error adding admin");
      },
    }
  );
  const handleFormSubmission = async (data) => {
    addUserMutation.mutate(data);
  };
  return (
    <div>
      <Typography mb={2} variant="h5" component="h2">
        New Admin
      </Typography>
      <Box
        onSubmit={handleSubmit(handleFormSubmission)}
        component="form"
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: 2,
        }}
        autoComplete="off"
      >
        <TextField required label="Full name" {...register("name")} />
        <TextField
          required
          label="Email"
          type="email"
          autoComplete="new-email"
          {...register("email")}
        />
        <TextField
          required
          label="Password"
          type="password"
          autoComplete="new-password"
          {...register("password")}
        />
        <div></div>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </Box>
    </div>
  );
};

export default NewAdmin;
