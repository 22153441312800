import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "../axios";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

const NewClass = () => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      fee: 0,
    },
  });
  const navigate = useNavigate();
  const newClassMutation = useMutation((data) => {
    axios.post("/classes", data);
  });

  const handleFormSubmission = (data) => {
    newClassMutation.mutate(data, {
      onSuccess: () => {
        toast.success("Class added successfully");
        navigate("/classes");
      },
      onError: () => {
        toast.error("Error adding class");
      },
    });
  };

  return (
    <div>
      <Typography mb={2} variant="h5" component="h2">
        New Class
      </Typography>
      <Box
        onSubmit={handleSubmit(handleFormSubmission)}
        component="form"
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: 2,
        }}
        autoComplete="off"
      >
        <TextField {...register("name")} label="Class name" required />
        <TextField {...register("fee")} label="Fee" type="number" />
        <div></div>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </Box>
    </div>
  );
};

export default NewClass;
