import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../axios";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

const columns = [
  { field: "fullName", headerName: "Full name", width: 400 },
  {
    field: "identificationNumber",
    headerName: "ID Number",
    width: 120,
  },
];

const Parents = () => {
  const { data, isLoading } = useQuery("parents", () =>
    axios("/parents").then((res) => res.data)
  );
  const navigate = useNavigate();
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1.5rem",
        }}
      >
        <Typography variant="h5" component="h2">
          Parents
        </Typography>
        <Button variant="contained" onClick={() => navigate("/parents/new")}>
          New Parent
        </Button>
      </Box>
      <Box sx={{ height: 650, width: "100%" }}>
        <DataGrid
          onRowDoubleClick={(params) => navigate(`/parents/${params.row.id}`)}
          rows={data || []}
          loading={isLoading}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
        />
      </Box>
    </div>
  );
};
export default Parents;
