import axios from "../axios";
import { useMutation, useQuery } from "react-query";
import { useParams } from "react-router-dom";
import EditStudentForm from "../components/EditStudentForm";
import { CircularProgress } from "@mui/material";

const EditStudent = () => {
  const { id } = useParams();

  const {
    data: student,
    isLoading: isStudentLoading,
    isError: isStudentError,
  } = useQuery(["student", id], () =>
    axios(`/students/${id}`).then((res) => res.data)
  );

  const {
    data: classes,
    isLoading: isClassesLoading,
    isError: isClassesError,
  } = useQuery("classes", () => axios.get("/classes").then((res) => res.data));

  const editStudentMutation = useMutation((data) =>
    axios.put(`/students/${id}`, data)
  );

  if (isStudentLoading || isClassesLoading) return <CircularProgress />;
  if (isStudentError || isClassesError) return <div>Error</div>;

  return (
    <EditStudentForm
      student={student}
      classes={classes}
      editStudentMutation={editStudentMutation}
    />
  );
};

export default EditStudent;
