import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../axios";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

const columns = [
  { field: "name", headerName: "Name", width: 200 },
  { field: "email", headerName: "Email", width: 250 },
];

const Users = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery("admins", () =>
    axios.get("/admins").then((res) => res.data)
  );
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1.5rem",
        }}
      >
        <Typography variant="h5" component="h2">
          Admins
        </Typography>
        <Button variant="contained" onClick={() => navigate("/admins/new")}>
          New Admin
        </Button>
      </Box>

      <Box sx={{ height: 650, width: "100%" }}>
        <DataGrid
          components={{
            LoadingOverlay: LinearProgress,
          }}
          loading={isLoading}
          onRowDoubleClick={(params) => navigate(`/users/${params.row.id}`)}
          rows={data || []}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
        />
      </Box>
    </div>
  );
};
export default Users;
