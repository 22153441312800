import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../axios";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

const columns = [
  { field: "name", headerName: "Class", width: 130 },
  { field: "fee", headerName: "Fees", width: 130 },
];

const Classes = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery("classes", () =>
    axios.get("/classes").then((res) => res.data)
  );
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1.5rem",
        }}
      >
        <Typography variant="h5" component="h2">
          Classes
        </Typography>
        <Button variant="contained" onClick={() => navigate("/classes/new")}>
          New Class
        </Button>
      </Box>

      <Box sx={{ height: 650, width: "100%" }}>
        <DataGrid
          components={{
            LoadingOverlay: LinearProgress,
          }}
          loading={isLoading}
          onRowDoubleClick={(params) => navigate(`/classes/${params.row.id}`)}
          rows={data || []}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
        />
      </Box>
    </div>
  );
};
export default Classes;
