import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";

const EditStudentForm = ({ student, classes, editStudentMutation }) => {
  const { handleSubmit, control, setValue } = useForm({
    defaultValues: {
      fullName: student.fullName,
      dateOfBirth: dayjs(student.dateOfBirth, "DD-MM-YYYY"),
      discount: student.discount,
      class: student.class ? student.class.id : "",
      gender: student.gender,
      isActive: student.isActive,
    },
  });

  const handleFormSubmission = (data) => {
    editStudentMutation.mutate(
      {
        ...data,
        dateOfBirth: data.dateOfBirth.format("DD-MM-YYYY"),
      },
      {
        onSuccess: () => {
          toast.success("Student updated successfully");
        },
        onError: () => {
          toast.error("Something went wrong");
        },
      }
    );
  };

  return (
    <div>
      <Typography variant="h5" component="h2" marginBottom={2}>
        Edit Student
      </Typography>
      <Box
        onSubmit={handleSubmit(handleFormSubmission)}
        component="form"
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: 2,
        }}
        autoComplete="off"
      >
        <Controller
          render={({ field }) => (
            <TextField {...field} required label="Full name" />
          )}
          control={control}
          name="fullName"
        />
        <Controller
          render={({ field }) => (
            <TextField {...field} required label="Discount %" type="number" />
          )}
          control={control}
          name="discount"
        />
        <Controller
          render={({ field }) => (
            <DatePicker
              {...field}
              required
              label="Date of birth"
              inputFormat="DD-MM-YYYY"
              renderInput={(params) => <TextField {...params} />}
            />
          )}
          control={control}
          name="dateOfBirth"
        />
        <Controller
          render={({ field }) => (
            <FormControl fullWidth required {...field}>
              <InputLabel id="class-label">Grade</InputLabel>
              <Select
                onChange={(e) => setValue("class", e.target.value)}
                defaultValue={student.class ? student.class.id : ""}
                labelId="class-label"
                label="Grade"
              >
                {classes.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          control={control}
          name="class"
        />
        <Controller
          render={({ field }) => (
            <FormControl {...field}>
              <FormLabel id="gender-label">Gender</FormLabel>
              <RadioGroup
                defaultValue={student.gender}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="MALE"
                  control={<Radio />}
                  label="Male"
                />
                <FormControlLabel
                  value="FEMALE"
                  control={<Radio />}
                  label="Female"
                />
              </RadioGroup>
            </FormControl>
          )}
          control={control}
          name="gender"
        />
        <Controller
          render={({ field }) => (
            <FormControlLabel
              {...field}
              control={<Checkbox defaultChecked={student.isActive} />}
              label="Active"
            />
          )}
          control={control}
          name="isActive"
        />
        <Button type="submit" variant="contained">
          Update Student
        </Button>
      </Box>
    </div>
  );
};

export default EditStudentForm;
