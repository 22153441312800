import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import dayjs from "dayjs";
import { Box } from "@mui/system";
import { useMutation, useQuery } from "react-query";
import axios from "../axios";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { DatePicker } from "@mui/x-date-pickers";

// https://day.js.org/docs/en/parse/string-format

const year = new Date().getFullYear();

const NewStudent = () => {
  // const { data: nextStudentID } = useQuery("nextId", () =>
  //   axios.get("/students/next-admission-number").then((res) => res.data)
  // );

  const { data: parents, error: isParentError } = useQuery("parents", () =>
    axios.get("/parents").then((res) => res.data)
  );

  const { data: classes, error: isClassError } = useQuery("classes", () =>
    axios.get("/classes").then((res) => res.data)
  );

  const addStudentMutation = useMutation((data) => {
    axios.post("/students", data);
  });

  const { register, handleSubmit, setValue, control } = useForm({
    defaultValues: {
      parent: "",
      identificationNumber: "",
      dateOfBirth: dayjs("2011-01-01T21:11:54", "DD-MM-YYYY"),
      phone: "",
      email: "",
      address: "",
      discount: 0,
    },
  });

  const [gender, setGender] = useState("");
  const [yearOfEntry, setYearOfEntry] = useState(year);
  const [gradeEntry, setGradeEntry] = useState("");
  const navigate = useNavigate();
  const handleYearOfEntryChange = (event) => {
    setYearOfEntry(event.target.value);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleGradeEntryChange = (event) => {
    setGradeEntry(event.target.value);
  };

  const setParent = (_, value) => {
    if (value !== null) {
      setValue("identificationNumber", value.identificationNumber);
      if (value.email) {
        setValue("email", value.email);
      }
      setValue("phone", value.phone);
      setValue("address", value.address);
    }
  };

  const handleFormSubmit = (data) => {
    addStudentMutation.mutate(
      {
        ...data,
        dateOfBirth: dayjs(data.dateOfBirth).format("DD-MM-YYYY"),
      },
      {
        onSuccess: () => {
          toast.success("Student added successfully");
          navigate("/students");
        },
        onError: () => {
          toast.error("Error adding student");
        },
      }
    );
  };

  if (isParentError || isClassError) return <div>Error</div>;

  return (
    <div>
      <Typography variant="h5" fontWeight={600} component="h2">
        New Student
      </Typography>
      <Typography variant="h6" mb={1} component="h3">
        Student Details
      </Typography>
      <Box
        onSubmit={handleSubmit(handleFormSubmit)}
        component="form"
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: 2,
        }}
        autoComplete="off"
      >
        <TextField required label="Full name" {...register("fullName")} />
        <Controller
          render={({ field }) => (
            <DatePicker
              {...field}
              required
              label="Date of birth"
              inputFormat="DD-MM-YYYY"
              renderInput={(params) => <TextField {...params} />}
            />
          )}
          control={control}
          name="dateOfBirth"
        />
        <FormControl required fullWidth>
          <InputLabel id="gender-label">Gender</InputLabel>
          <Select
            {...register("gender")}
            labelId="gender-label"
            value={gender}
            label="Gender"
            onChange={handleGenderChange}
          >
            <MenuItem value="MALE">Male</MenuItem>
            <MenuItem value="FEMALE">Female</MenuItem>
          </Select>
        </FormControl>
        <TextField
          type="number"
          required
          label="Discount"
          {...register("discount")}
        />
        <FormControl required fullWidth>
          <InputLabel id="year-of-entry-label">Year of entry</InputLabel>
          <Select
            {...register("yearOfEntry")}
            labelId="year-of-entry-label"
            value={yearOfEntry}
            label="Year of entry"
            onChange={handleYearOfEntryChange}
          >
            <MenuItem value={year}>{year}</MenuItem>
            <MenuItem value={year + 1}>{year + 1}</MenuItem>
            <MenuItem value={year + 2}>{year + 2}</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth required>
          <InputLabel id="grade-label">Grade</InputLabel>
          <Select
            {...register("grade")}
            labelId="grade-label"
            value={gradeEntry}
            label="Grade entry"
            onChange={handleGradeEntryChange}
          >
            {classes &&
              classes.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Typography variant="h6" mt={2} component="h3">
          Parent Details
        </Typography>
        <div></div>
        <Autocomplete
          freeSolo
          options={parents || []}
          onChange={setParent}
          getOptionLabel={(parent) => parent.fullName}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.fullName}
              </li>
            );
          }}
          renderInput={(params) => (
            <TextField
              key={params.id}
              {...register("parent")}
              required
              {...params}
              label="Parent name"
            />
          )}
        />
        {/* to make the text go up when there is text in the input wrap it in a controller */}
        <Controller
          render={({ field }) => (
            <TextField {...field} required label="Identification number" />
          )}
          control={control}
          name="identificationNumber"
        />
        <Controller
          render={({ field }) => (
            <TextField required {...field} label="Phone" />
          )}
          control={control}
          name="phone"
        />
        <Controller
          render={({ field }) => <TextField {...field} label="Email" />}
          control={control}
          name="email"
        />
        <Controller
          render={({ field }) => (
            <TextField
              {...field}
              required
              label="Address"
              minRows={4}
              multiline
            />
          )}
          control={control}
          name="address"
        />

        <div></div>
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </Box>
    </div>
  );
};

export default NewStudent;

// when calculating fees consider year of entry
// when incrementing grade consider year of entry
