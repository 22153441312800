import { Box, Typography } from "@mui/material";

import L from "leaflet";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import MarkerClusterGroup from "react-leaflet-cluster";

import { useQuery } from "react-query";
import axios from "../axios";

const customIcon = new L.Icon({
  iconUrl: require("./location.svg").default,
  iconSize: new L.Point(40, 47),
});

const Home = () => {
  const { data: addressPoints } = useQuery("addressPoints", () =>
    axios("/parents/addressPoints").then((res) => res.data)
  );

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Student Locations
      </Typography>
      {addressPoints && (
        <MapContainer
          style={{ height: "80vh" }}
          center={[6.85, 79.88]}
          zoom={13}
          maxZoom={18}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

          <MarkerClusterGroup chunkedLoading>
            {addressPoints.map((point) => (
              <Marker
                icon={customIcon}
                key={point.parentID}
                position={[point.lat, point.lng]}
                // title={point.parentID}
              />
            ))}
          </MarkerClusterGroup>
        </MapContainer>
      )}
    </Box>
  );
};
export default Home;
